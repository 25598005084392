import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import photoThomas from "../images/Thomas_Karolak_Parsk.jpg";
// import PhotoThomas from "images/Thomas_Karolak_Parsk.jpg";
import {
  Box,
  Text,
  useColorMode,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const About = () => {
  const { colorMode } = useColorMode();

  function PhotoKarolak() {
    return (
      <StaticImage
        // src="../images/Thomas_Karolak_Parsk.jpg"
        src="../images/Thomas_Karolak_Parsk.png"
        // src={PhotoThomas}
        // layout="fixed"
        alt="Thomas Karolak"
        // width="800px"
        // height="100%"
        placeholder="blurred"
      />
    );
  }

  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color="gray.500"
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>

        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l, #CF4646 , #CF9663)"
          bgClip="text"
        >
          Pourquoi Parsk ?
        </Text>
        <br />
        <br />
        <PhotoKarolak />
        <Text fontSize="xl" my="40px">
          Parce que POURQUOI est la question à laquelle je me confronte, ou plus
          exactement celle à laquelle je confronte mes clients pour cerner leurs
          besoins. <br />
          <br />
          Ce POURQUOI est depuis{" "}
          <b>
            plus de 20 ans le dénominateur commun des transformations que j’ai
            pu opérer en tant que CDO au sein d'entreprises à fort enjeu de
            marque et d’audience
          </b>{" "}
          (TF1, M6, Vivendi, RTL Group, Les Echos – Le Parisien) et des
          transformations d’entreprises de tous secteurs que j’accompagne
          désormais comme consultant ou mentor.
          <br />
        </Text>

        {/* <StaticImage
          // src="../images/Thomas_Karolak_Parsk.jpg"
          src="../images/Thomas_Karolak_Parsk.jpg"
          // src={photoThomas}
          alt="Thomas Karolak"
          // width="800px"
          height="100%"
          placeholder="blurresd"
        /> */}
        {/* <StaticImage
          src={photoThomas}
          alt="Thomas Karolak"
          // width="800px"
          // height="100%"
          height="300px"
          width="800px"
          // placeholder="blurresd"
        /> */}
        <Text fontSize="xl" my="40px">
          {/* <br /> */}
          Ce POURQUOI est le point de départ d'un processus pour lequel{" "}
          <b>
            le mindset de l'entreprise doit changer pour faire face, non pas à
            un changement d'un moment mais répondre à un changement permanent
          </b>
          . L'entreprise doit faire des choix en lien et en cohérence avec son
          passé et son ambition.
          <br />
          <br />
          Ces initiatives impliquent des efforts axés sur l'expérience des
          employés et sur l'expérience client. Et en réponse à ce POURQUOI,
          PARSK place{" "}
          <b>
            le Produit comme trait d’union entre la stratégie et l’exécution
          </b>
          .
        </Text>

        <Text fontSize="xl" my="40px" color="gray.500">
          Thomas Karolak, fondateur de PARSK
        </Text>

        {/* <Heading
          as="h2"
          size="xl"
          mt="40px"
          mb="20px"
          fontWeight="extrabold"
          lineHeight="1.2"
          letterSpacing="tight"
          textTransform="uppercase"
        >
          Exemple de missions
        </Heading> */}
        {/* <List spacing="40px" fontSize="xl">
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Co-construction de la stratégie d’une entreprise ou d’une
            organisation
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Co-construction d’une feuille de route de transformation
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Réévaluation de la raison d’être et de la roadmap opérationnel
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Refonte de l’expérience client
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Développement du leadership individuel et collectif
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Evolution de la culture d’entreprise vers plus de collaboratif
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="#CF4646" />
            Accompagnement de projets innovants/intrapreneuriat
          </ListItem>
        </List> */}
      </Box>
      <Footer />
    </Box>
  );
};

export default About;
